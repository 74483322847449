import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import CallToActionButton from "../../../../components/CallToActionButton/call-to-action-button";
import LoadingSpinner from "../../../../components/loading-spinner";
import { FinanceContext } from "../../../../context/finance-context";
import { useLoanSubmission } from "../../../../hooks/loanApplication.hooks";
import { BackArrow, NextArrow } from "../../../../svgs";
import { useMyInfo } from "../../../../hooks/user.hooks.ts";

const LoanApplicationButtonGroup = ({
  uploadDocumentMutation = {},
  setApplicationStep = () => {},
  applicationStep = 0,
  files,
  opportunityId = "",
}) => {
  const { selectedScenarioId, selectedScenarioDetail } = useContext(
    FinanceContext
  );
  const { data: userInfo } = useMyInfo();

  const createLoanSubmissionMutation = useLoanSubmission(opportunityId);
  const { isLoading } = createLoanSubmissionMutation;
  const { isLoading: isLoadingDocument } = uploadDocumentMutation;
  const isOwner = selectedScenarioDetail?.mainUserId === userInfo?.id;

  const handleNext = async () => {
    if (applicationStep === 2 && isOwner) {
      const formData = new FormData();
      files.map((file) => formData.append(`files`, file));
      if (!opportunityId) {
        await createLoanSubmissionMutation.mutateAsync(selectedScenarioId);
      }
      if (files.length) {
        await uploadDocumentMutation.mutateAsync({ params: formData });
      }
    }
    setApplicationStep((prevState) => {
      return prevState >= 3 ? prevState : prevState + 1;
    });
  };

  const handlePrevious = () => {
    setApplicationStep((prevState) => {
      let step = prevState;
      step = step > 0 ? (step -= 1) : step;
      return step;
    });
  };

  return (
    <div
      className={classNames("application-button-container", {
        "step-1": applicationStep === 0,
      })}
    >
      {applicationStep > 0 && applicationStep < 4 && (
        <CallToActionButton
          title="Previous"
          icon={<BackArrow />}
          onClick={handlePrevious}
        />
      )}

      {applicationStep < 3 && (
        <CallToActionButton
          onClick={handleNext}
          title={
            isLoading || isLoadingDocument ? <LoadingSpinner /> : "Next Section"
          }
          icon={<NextArrow />}
          iconDisabled={isLoading || isLoadingDocument}
          disabled={isLoading || isLoadingDocument}
        />
      )}
    </div>
  );
};

LoanApplicationButtonGroup.propTypes = {
  setApplicationStep: PropTypes.func,
  applicationStep: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array,
};

export default LoanApplicationButtonGroup;
